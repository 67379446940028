import React from 'react'
import { Link } from 'gatsby'

const SideCol = ({ links, textBlock }) => (
  <div>
    <div>
      {
        links.map(
          (el, i) => (
            <div key={`side_${i}`}>
              <Link to={el.path} className={`font-bold text-4xl lg:text-2xl leading-tight hover:text-green-gefa underline ${el.selected && 'text-green-gefa'}`} title={el.text} >{el.text}</Link>
            </div>
          )
        )
      }
    </div>
    <p className='mt-12 text-4xl lg:text-2xl font-bold md:font-normal text-green-gefa leading-tight'>
      {textBlock}
    </p>
  </div>
)

export default SideCol
