import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Seo from '../../components/seo'
import SecondaryHeading from '../../components/SecondaryHeading'
import SectionDoubleColumn from '../../components/SectionDoubleColumn'
import SideCol from '../../components/SectionDoubleColumn/SideCol'
import Image from '../../components/image'
import generateLink from '../../utils/generateLink'
import ReactMarkdown from 'react-markdown'

const Company = ({ langKey, location }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          pagesData {
            company {
              mainSection {
                story {
                  subSections {
                    paragraphs {
                      de
                      en
                      it
                    }
                    images
                    title {
                      de
                      en
                      it
                    }
                  }
                  title {
                    de
                    en
                    it
                  }
                }
              }
              pageTitle {
                de
                en
                it
              }
              sideSection {
                firstBlock {
                  de
                  en
                  it
                }
                links {
                  text {
                    en
                    it
                    de
                  }
                  path
                }
              }
            }
          }
        }
      }
    }
      `
  )

  const {
    pageTitle,
    mainSection: {
      story
    },
    sideSection
  } = site.siteMetadata.pagesData.company

  const mainCol = (
    <div>
      <div>
        <h2 className='text-3xl underline font-bold mb-6'>{story.title[langKey]}</h2>
        <div className='border-l-2 border-green-gefa pl-5'>
          {
            story.subSections.map((el, i) => (
              <div key={`mission_${i}`} className={`${i !== 0 && 'pt-10'}`}>
                <Fade right>
                  <div>
                    <div className='timelien-pinpoint border-green-gefa' />
                    <h3 className='text-2xl underline text-green-gefa font-bold mb-2'>{el.title[langKey]}</h3>
                    {
                      el.images.length > 0 && el.images !== '' &&
                        <div className='mt-10 pb-4'>
                          <Image imgRef={el.images[0]} />
                        </div>
                    }
                    {
                      el.paragraphs.map((elp, ip) => <div className='mt-6' key={`mission_paragraph_${ip}`}><ReactMarkdown source={elp[langKey]} /></div>)
                    }
                  </div>
                </Fade>
              </div>
            ))
          }
        </div>
        <div className='border-l-2 border-green-gefa border-dashed h-8' />
      </div>
    </div>
  )

  const linksForSide = sideSection.links.map((el) => ({ path: generateLink(langKey, '/company/' + el.path), text: el.text[langKey], selected: el.path === '' }))
  const sideCol = <SideCol links={linksForSide} textBlock={sideSection.firstBlock[langKey]} />

  return (
    <>
      <Seo title={pageTitle[langKey]} urlPath={location.pathname} />
      <SecondaryHeading title={pageTitle[langKey]} />
      <SectionDoubleColumn mainCol={mainCol} sideCol={sideCol} mainVisibleInSmallScreen />
    </>
  )
}

export default Company
