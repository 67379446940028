import React from 'react'
import Section from '../Section'

const SectionDoubleColumn = ({ mainCol, sideCol, mainHiddenInSmallScreen, sideHiddenInSmallScreen }) => {
  const visibilitClassMain = mainHiddenInSmallScreen ? 'hidden lg:block' : ''
  const visibilitClassSide = sideHiddenInSmallScreen ? 'hidden lg:block' : ''

  return (
    <Section>
      <div className='max-w-screen-lg m-auto grid grid-cols-1 lg:grid-cols-12 px-6 lg:px-0'>
        <div className={`col-span-12 lg:col-span-8 ${visibilitClassMain}`}>
          {mainCol}
        </div>
        <div className={`mt-20 lg:mt-0 col-start-0 lg:col-start-10 lg:col-span-3 ${visibilitClassSide}`}>
          {sideCol}
        </div>
      </div>
    </Section>
  )
}

export default SectionDoubleColumn
